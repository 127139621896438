import React from "react";
import {
	Box,
	Checkbox,
	FormControlLabel, Tooltip
} from "@mui/material";
import {

} from "@mui/styles";
import agent from "../../../../../../agent/agent";
import clsx from "clsx";
import {Help as HelpIcon} from "@mui/icons-material";

const CheckboxApi = (props) => {
	const [options, setOptions] = React.useState([...(props?.initOptions || [])]);
	const handleGetOptions = async () => {
		const res = await agent.get(`/api/admin/products/characteristic-variants-by-id/${ props.name }`)
			.then((res) => (res?.data || []))
			.catch(() => []);
		setOptions(res);
		if (props.value !== "") {
			handleInitChecked(res);
		}
		if (props.value === "") {
			const defaultOption = res.find((t) => Boolean(t.is_default));
			if (!defaultOption) {
				return
			}
			handleChange(null, defaultOption?.value);
		}
	};
	React.useEffect(() => {
		(async () => {
			await handleGetOptions();
		})();
	}, []);

	const [checked, setChecked] = React.useState(false);
	const handleChange = (event, value) => {
		const valueId = options.find((opt) => Boolean(String(opt.value || '').toLowerCase() === String(Boolean(value) ? 'да' : 'нет')))?.id || null;
		props.onChange({
			target: {
				name: props.name,
				value: valueId,
			}
		})
	};
	const handleInitChecked = (resOptions = []) => {
		let _options = options;
		if (_options.length <= 0) {
			_options = [...(options?.initOptions || [])];
		}
		if (_options.length <= 0) {
			_options = [...(resOptions || [])];
		}
		if (_options.length <= 0) {
			setChecked(false);
			return
		}
		const isChecked = Boolean(String(_options.find((opt) => Boolean(String(opt?.id) === String(props.value)))?.value || '').toLowerCase() === 'да');
		setChecked(isChecked);
	};
	React.useEffect(() => {
		handleInitChecked();
	}, [props.value]);

	return (
		<FormControlLabel
			label={(<React.Fragment>
				<Box className={props.classesOptionHead} style={{marginBottom: "2px"}}>
					<span dangerouslySetInnerHTML={{__html: props?.label || ""}}/>
					{Boolean(props?.adminNote) && (
						<Tooltip arrow title={<React.Fragment><span dangerouslySetInnerHTML={{ __html: props?.adminNote || "" }}/></React.Fragment>}>
							<HelpIcon color="primary" sx={{fontSize: 20, marginLeft: "4px"}}/>
						</Tooltip>
					)}
				</Box>
			</React.Fragment>)}
			value={checked}
			checked={checked}
			control={<Checkbox />}
			onChange={handleChange}
		/>
	)
}

export default CheckboxApi
