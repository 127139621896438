import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Grid,
	Button,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	TextField, Autocomplete
} from "@mui/material";
import {Formik} from "formik";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification";
import {
	AutocompleteSites,
	DialogConfirmation
} from "../../../../../components";
import agent from "../../../../../agent/agent";
import urls from "../../../../../variables/urls";
import * as Yup from "yup";

const initialValues = {
	name: "",
	code: "",
	brand_id: "",
	characteristic_group_id: "",
	category_id: 1,
	extraWebsites: [],
}

class DialogCreateProduct extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			characteristicGroupIds: [],
			categoryIds: [],
			brandIds: [],

			product: {},

			onSubmit: null,

			isOpen: false,
			isDisabledCode: false
		}

		this.refFormik = React.createRef();
		this.refDialogConfirmation = React.createRef();
	}

	open = ({ onSubmit }) => {
		this.setState({
			onSubmit: onSubmit,

			isOpen: true
		}, async () => {
			await this.getServices();
		})
	}
	close = () => {
		this.setState({
			isOpen: false
		})
	}

	submitProduct = (form) => {
		let body = {...form};
		body.name = String(form?.name || "").replace(/\s+/g, ' ').trim();
		body.code = String(form?.name || "").replace(/\s+/g, ' ').trim();
		this.state.onSubmit(body);
	}

	// Получение настроек товара
	getServices = async () => {
		const characteristicGroupIds = await agent.get('/admin/api-shop-characteristic-groups/list?ngrestCallType=list&expand=productsCount%2CcharacteristicsCount&fields=id%2Cid%2Cname%2Cslug%2Csort%2Cis_for_product_set').then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		const data = await agent.get(urls.getServices).then((res) => {
			return res.data.service
		}).catch((err) => {
			return {}
		});
		const categoryIds = data?.category_id?.selectdata || [];
		const brandIds = data?.brand_id?.selectdata || [];

		this.setState({
			characteristicGroupIds,
			categoryIds,
			brandIds
		})
	}

	// Изменение формы товара
	changeProduct = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);

		if (name === "brand_id" || name === "characteristic_group_id") {
			(async () => {
				await this.getProductCodeFromApi();
			})();
		}
	}
	changeProductAutocomplete = ({ target }, value) => {
		let values = this.refFormik.current.values;
		values.category_id = value?.value || "";
		this.refFormik.current.setValues(values)
	}

	// Получение code из API
	getProductCodeFromApi = async () => {
		const enableFUnction = process?.env?.REACT_APP_ENABLE_DYNAMIC_CREATION_ARTICLE || 'false';
		if (!enableFUnction || enableFUnction === 'false' || enableFUnction === '0') {
			return
		}

		const values = this.refFormik.current.values;
		if (!values?.characteristic_group_id || !values?.brand_id) {
			return
		}

		const characteristicGroup = (this.state.characteristicGroupIds || []).find((t) => t.id === values?.characteristic_group_id);
		if (!characteristicGroup?.is_for_product_set) {
			this.setState({
				isDisabledCode: false
			})
			return
		}

		this.setState({isDisabledCode: true});

		const brand = (this.state.brandIds || []).find((t) => t.value === values?.brand_id);
		const apiCode = await agent.get(`/api/vendor-code/brands/${values?.brand_id}`).then((res) => {
			return res.data?.data || ""
		}).catch(() => {
			return ""
		});
		if (!apiCode) {
			const link = `<a href="https://stage.miasofiahome.ru/admin#!/default/9/shopadmin/brands/index/${values?.brand_id}" target="_blank" style="color: #3855EC">тут</a>`
			this.refDialogConfirmation.current.onOpen({
				title: "Уведомление",
				message: `Для производителя "${brand.label}" не заполнен символьный код для формирования артикула. Заполните его ${link}`,
				acceptButtonTitle: 'ОК',

				hideCancel: true
			})

			values.code = "";
			this.refFormik.current.setValues(values);

			return
		}

		values.code = apiCode;
		this.refFormik.current.setValues(values);
	}

	_categoryGetOptionLabel = (params) => {
		if (typeof params !== "object") {
			return (this.state.categoryIds || []).find((t) => t.value === params)?.label || ""
		}

		return params?.label
	}

	render () {
		const {
			characteristicGroupIds,
			categoryIds,
			brandIds,

			isOpen,
			isDisabledCode
		} = this.state;

		return (
			<>
				<Dialog
					open={isOpen}
					fullWidth
					maxWidth="sm"
					onClose={this.close}
				>
					<DialogTitle>
						<Typography variant="h3">Создание товара</Typography>
					</DialogTitle>

					<DialogContent>
						<Formik
							innerRef={this.refFormik}
							initialValues={{...initialValues}}
							validationSchema={validationSchema}
							onSubmit={this.submitProduct}
						>
							{(props) => {
								const {
									values,
									errors,
									touched
								} = props;

								return (
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												value={values.name}
												error={Boolean(touched.name && errors.name)}
												helperText={touched.name && errors.name}
												name="name"
												label="Название"
												fullWidth
												size='small'

												onChange={this.changeProduct}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												value={values.code}
												error={Boolean(touched.code && errors.code)}
												helperText={touched.code && errors.code}
												name="code"
												label="Артикул"
												fullWidth
												disabled={isDisabledCode}
												size='small'

												onChange={this.changeProduct}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControl
												fullWidth
												error={Boolean(touched.characteristic_group_id && errors.characteristic_group_id)}
												size="small"
											>
												<InputLabel>Тип товара</InputLabel>
												<Select
													value={values.characteristic_group_id}
													error={Boolean(touched.characteristic_group_id && errors.characteristic_group_id)}
													helperText={touched.characteristic_group_id && errors.characteristic_group_id}
													label="Тип товара"
													name="characteristic_group_id"
													fullWidth
													onChange={this.changeProduct}
												>
													{(characteristicGroupIds || []).map((item, idx) => (
														<MenuItem
															key={`characteristicGroupIds-${idx}`}
															value={item.id}
														>{item.name}</MenuItem>
													))}
												</Select>
												{Boolean(touched.characteristic_group_id && errors.characteristic_group_id) && (
													<FormHelperText>{touched.characteristic_group_id && errors.characteristic_group_id}</FormHelperText>
												)}
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<FormControl
												fullWidth
												error={Boolean(touched.brand_id && errors.brand_id)}
												size="small"
											>
												<InputLabel>Бренд</InputLabel>
												<Select
													value={values.brand_id}
													error={Boolean(touched.brand_id && errors.brand_id)}
													helperText={touched.brand_id && errors.brand_id}
													label="Бренд"
													name="brand_id"
													fullWidth
													onChange={this.changeProduct}
												>
													{(brandIds || []).map((item, idx) => (
														<MenuItem
															key={`brandIds-${idx}`}
															value={item.value}
														>{item.label}</MenuItem>
													))}
												</Select>
												{Boolean(touched.brand_id && errors.brand_id) && (
													<FormHelperText>{touched.brand_id && errors.brand_id}</FormHelperText>
												)}
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<AutocompleteSites
												value={values.extraWebsites}
												name="extraWebsites"
												error={Boolean(touched.extraWebsites && errors.extraWebsites)}
												helperText={touched.extraWebsites && errors.extraWebsites}
												onChange={this.changeProduct}
											/>
										</Grid>

										{Boolean(false) && (
											<Grid item xs={12}>
												<Autocomplete
													fullWidth
													name="category_id"
													size="small"
													options={categoryIds}
													value={values.category_id}
													error={Boolean(touched.category_id && errors.category_id)}
													helperText={touched.category_id && errors.category_id}
													onChange={this.changeProductAutocomplete}
													getOptionLabel={this._categoryGetOptionLabel}
													disableClearable={true}
													renderInput={(params) => <TextField
														{...params}
														error={Boolean(touched.category_id && errors.category_id)}
														helperText={touched.category_id && errors.category_id}
														label="Категория"
														fullWidth
													/>}
												/>
											</Grid>
										)}
									</Grid>
								)
							}}
						</Formik>
					</DialogContent>

					<DialogActions>
						{Boolean(!!this.refFormik.current) && (
							<Button
								variant="contained"
								fullWidth
								onClick={this.refFormik.current.handleSubmit}
							>Создать товар</Button>
						)}
					</DialogActions>
				</Dialog>

				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>
			</>
		)
	}
}

const validationSchema = Yup.object().shape({
	name: Yup.string().max(255, 'Максимальная длина 255 символов').required('Заполните поле'),
	code: Yup.string().max(255, 'Максимальная длина 255 символов').nullable().required('Заполните поле'),
	characteristic_group_id: Yup.string().nullable().required('Заполните поле'),
	category_id: Yup.string().nullable().required('Заполните поле'),
	brand_id: Yup.string().nullable().required('Заполните поле'),
	extraWebsites: Yup.array().min(1, 'Заполните поле')
});

export default DialogCreateProduct
